/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TransactionStatus = "INCOMPLETE" | "REVERSED" | "SUCCESSFUL" | "%future added value";
export type TransactionType = "TRANSIT_JOURNEY" | "%future added value";
export type TransactionQueryVariables = {
    organisationId: string;
    transactionId: string;
};
export type TransactionQueryResponse = {
    readonly organisation: {
        readonly transaction: {
            readonly id: string;
            readonly transactionType: TransactionType;
            readonly organisations: ReadonlyArray<{
                readonly organisation: {
                    readonly name: string | null;
                };
                readonly role: string;
            }>;
            readonly status: TransactionStatus;
            readonly createdDate: string;
            readonly transactionDate: string;
            readonly modifiedDate: string;
            readonly user: {
                readonly id: string | null;
                readonly firstName: string | null;
                readonly surname: string | null;
            } | null;
            readonly startLocation?: {
                readonly name: string | null;
                readonly lat: number | null;
                readonly lon: number | null;
            } | null;
            readonly endLocation?: {
                readonly name: string | null;
                readonly lat: number | null;
                readonly lon: number | null;
            } | null;
            readonly " $fragmentRefs": FragmentRefs<"Metadata_object" | "ActivityLog_object">;
        } | null;
    } | null;
};
export type TransactionQuery = {
    readonly response: TransactionQueryResponse;
    readonly variables: TransactionQueryVariables;
};



/*
query TransactionQuery(
  $organisationId: ID!
  $transactionId: ID!
) {
  organisation(id: $organisationId) {
    transaction(id: $transactionId) {
      __typename
      id
      transactionType
      organisations {
        organisation {
          name
          id
        }
        role
      }
      status
      createdDate
      transactionDate
      modifiedDate
      user {
        id
        firstName
        surname
      }
      ...Metadata_object
      ...ActivityLog_object
      ... on TransitJourneyTransaction {
        startLocation {
          name
          lat
          lon
        }
        endLocation {
          name
          lat
          lon
        }
      }
    }
    id
  }
}

fragment ActivityLog_object on Object {
  __isObject: __typename
  activityLog {
    edges {
      node {
        type
        action
        timestamp
        input
      }
      cursor
    }
  }
}

fragment Metadata_object on Object {
  __isObject: __typename
  id
  metadata {
    name
    value
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transactionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "transactionId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modifiedDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "surname",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lat",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lon",
    "storageKey": null
  }
],
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GeoLocation",
      "kind": "LinkedField",
      "name": "startLocation",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeoLocation",
      "kind": "LinkedField",
      "name": "endLocation",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    }
  ],
  "type": "TransitJourneyTransaction",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transaction",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganisationRole",
                "kind": "LinkedField",
                "name": "organisations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "kind": "LinkedField",
                    "name": "organisation",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Metadata_object"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActivityLog_object"
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganisationRole",
                "kind": "LinkedField",
                "name": "organisations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "kind": "LinkedField",
                    "name": "organisation",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Metadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityLogConnection",
                    "kind": "LinkedField",
                    "name": "activityLog",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ActivityLogEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ActivityLog",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "action",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timestamp",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "input",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Object",
                "abstractKey": "__isObject"
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e00b7a7bdb9491a81523212da02c020",
    "id": null,
    "metadata": {},
    "name": "TransactionQuery",
    "operationKind": "query",
    "text": "query TransactionQuery(\n  $organisationId: ID!\n  $transactionId: ID!\n) {\n  organisation(id: $organisationId) {\n    transaction(id: $transactionId) {\n      __typename\n      id\n      transactionType\n      organisations {\n        organisation {\n          name\n          id\n        }\n        role\n      }\n      status\n      createdDate\n      transactionDate\n      modifiedDate\n      user {\n        id\n        firstName\n        surname\n      }\n      ...Metadata_object\n      ...ActivityLog_object\n      ... on TransitJourneyTransaction {\n        startLocation {\n          name\n          lat\n          lon\n        }\n        endLocation {\n          name\n          lat\n          lon\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ActivityLog_object on Object {\n  __isObject: __typename\n  activityLog {\n    edges {\n      node {\n        type\n        action\n        timestamp\n        input\n      }\n      cursor\n    }\n  }\n}\n\nfragment Metadata_object on Object {\n  __isObject: __typename\n  id\n  metadata {\n    name\n    value\n  }\n}\n"
  }
};
})();
(node as any).hash = '35611be40488c03e8c0d053ee8d2223a';
export default node;
