/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type WikiDocumentQueryVariables = {
    organisationId: string;
    documentId: string;
};
export type WikiDocumentQueryResponse = {
    readonly organisation: {
        readonly wikiDocument: {
            readonly id: string;
            readonly title: string;
            readonly article: string;
            readonly modifiedDate: string;
            readonly createdDate: string;
            readonly wikiDocuments: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly title: string;
                        readonly excerpt: string;
                    };
                }>;
            };
        } | null;
    } | null;
};
export type WikiDocumentQuery = {
    readonly response: WikiDocumentQueryResponse;
    readonly variables: WikiDocumentQueryVariables;
};



/*
query WikiDocumentQuery(
  $organisationId: ID!
  $documentId: ID!
) {
  organisation(id: $organisationId) {
    wikiDocument(id: $documentId) {
      id
      title
      article
      modifiedDate
      createdDate
      wikiDocuments(first: 3) {
        edges {
          node {
            id
            title
            excerpt
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "documentId"
    }
  ],
  "concreteType": "WikiDocument",
  "kind": "LinkedField",
  "name": "wikiDocument",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "article",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "modifiedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        }
      ],
      "concreteType": "WikiDocumentConnection",
      "kind": "LinkedField",
      "name": "wikiDocuments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WikiDocumentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WikiDocument",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "excerpt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "wikiDocuments(first:3)"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WikiDocumentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WikiDocumentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "df6b3faed97fc7383b38f90eb3d70cea",
    "id": null,
    "metadata": {},
    "name": "WikiDocumentQuery",
    "operationKind": "query",
    "text": "query WikiDocumentQuery(\n  $organisationId: ID!\n  $documentId: ID!\n) {\n  organisation(id: $organisationId) {\n    wikiDocument(id: $documentId) {\n      id\n      title\n      article\n      modifiedDate\n      createdDate\n      wikiDocuments(first: 3) {\n        edges {\n          node {\n            id\n            title\n            excerpt\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '8db2c2607ba078faf30cae497ed7ae1d';
export default node;
