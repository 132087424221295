import React from 'react';
import Organisation from './Organisation';
import { graphql } from 'babel-plugin-relay/macro'
import { QueryRenderer } from 'react-relay'
import environment from './relayenv';
import {
  Switch,
  Route
} from "react-router-dom";
import { AppQuery } from './__generated__/AppQuery.graphql';
import { Backdrop, CircularProgress } from '@material-ui/core';
function App() {
  document.title="Beep";
  return (
    <QueryRenderer<AppQuery>
        environment={environment}
        query={graphql`
        query AppQuery{
          me {
            id
            firstName
            surname
            organisations {
              edges {
                node {
                  organisation {
                    id
                    name
                  }
                  permissions
                }
              } 
            }
          }
        }        
        `}
        variables={{}}
        render={({error, props}) => {
          if (error) {
            return <div>Error!</div>;
          }
          if (!props) {
            return (<Backdrop open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>);
          }
          return (<Switch>
            <Route path={`/:organisationId`}>
              <Organisation />
            </Route>
          </Switch>);
        }}
      />
    
  );
}


export default App;
