/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WikiMenuRefetchQueryVariables = {
    organisationId: string;
};
export type WikiMenuRefetchQueryResponse = {
    readonly organisation: {
        readonly " $fragmentRefs": FragmentRefs<"Wiki_wikiDocuments">;
    } | null;
};
export type WikiMenuRefetchQuery = {
    readonly response: WikiMenuRefetchQueryResponse;
    readonly variables: WikiMenuRefetchQueryVariables;
};



/*
query WikiMenuRefetchQuery(
  $organisationId: ID!
) {
  organisation(id: $organisationId) {
    ...Wiki_wikiDocuments
    id
  }
}

fragment Wiki_wikiDocuments on Organisation {
  wikiDocuments(first: 10000) {
    edges {
      node {
        id
        title
        parentDocument {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WikiMenuRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Wiki_wikiDocuments"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WikiMenuRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000
              }
            ],
            "concreteType": "WikiDocumentConnection",
            "kind": "LinkedField",
            "name": "wikiDocuments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WikiDocumentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WikiDocument",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WikiDocument",
                        "kind": "LinkedField",
                        "name": "parentDocument",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "wikiDocuments(first:10000)"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dbb84b7901fe6246e7fd4b2d67d70c6",
    "id": null,
    "metadata": {},
    "name": "WikiMenuRefetchQuery",
    "operationKind": "query",
    "text": "query WikiMenuRefetchQuery(\n  $organisationId: ID!\n) {\n  organisation(id: $organisationId) {\n    ...Wiki_wikiDocuments\n    id\n  }\n}\n\nfragment Wiki_wikiDocuments on Organisation {\n  wikiDocuments(first: 10000) {\n    edges {\n      node {\n        id\n        title\n        parentDocument {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ac728f65416e973f94d2aa24b4700d6c';
export default node;
