import React, { Suspense } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Backdrop, CircularProgress } from '@material-ui/core';
import { graphql } from 'babel-plugin-relay/macro'
import { enGB } from 'date-fns/locale'

import {
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import { usePaginationFragment } from 'react-relay/hooks';
import { formatRelative } from 'date-fns';
import { Entity } from './Entity';
import { EntitiesPaginationQuery } from './__generated__/EntitiesPaginationQuery.graphql';

function Entities(props : {organisation: any}) {
    let match = useRouteMatch<{organisationId:string}>();
    //let { organisationId }:{organisationId:string} = useParams();
    /*return(
        <div></div>
    );*/
    const {data/*, loadNext*/} = usePaginationFragment<EntitiesPaginationQuery, any>(
        graphql`
          fragment Entities_organisation on Organisation
          @refetchable(queryName: "EntitiesPaginationQuery")
          @argumentDefinitions(
            count: {type: "Int", defaultValue: 10},
            cursor: {type: "Int", defaultValue: -1},
          ) {
            entities(first: $count, after: $cursor)
            @connection(key: "Entities_organisation_entities") {
              edges {
                node {
                  id
                  name
                  createdDate
                }
              }
            }
          }
        `,
        props.organisation,
      );    
    return (
        <Switch>
            <Route path={`${match.path}/:entityId`} render={({match}) => {
              return (<Suspense fallback={<Backdrop open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>}>
                <Entity organisationId={match.params.organisationId} entityId={match.params.entityId} />
              </Suspense>);
            }}>
            </Route>
            <Route path={match.path}>
                <h2>Entities</h2>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                        <TableCell>Entity</TableCell>
                        <TableCell>Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {(data.entities?.edges ?? []).map((edge: { node: { id: React.Key | null | undefined; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; createdDate: string | number | Date; }; }) => {
                        return (
                            <Suspense fallback={<div></div>}>
                                <TableRow key={edge.node.id}>
                                    <TableCell component="th" scope="row">
                                    <Link to={`${match.url}/${edge.node.id}`}>{edge.node.name}</Link>
                                    </TableCell>
                                    <TableCell>{edge.node.createdDate===null ? '' : formatRelative(new Date(edge.node.createdDate), new Date(),{locale:enGB})}</TableCell>
                                </TableRow>
                            </Suspense>
                        );
                    })}
                    </TableBody>
                    </Table>
                </TableContainer>
            </Route>
          </Switch>);
  }

export default Entities;