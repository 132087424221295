import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import React, { Suspense, useEffect } from 'react';
import { Backdrop, CircularProgress, Grid, Paper } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronRight, mdiPlus } from '@mdi/js';
import { createRefetchContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro'


import {WikiDocument, CreateWikiDocument} from './WikiDocument';
import { Wiki_wikiDocuments } from './__generated__/Wiki_wikiDocuments.graphql';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useParams
} from "react-router-dom";
const wikiEvent = {
  list: new Map(),
  on(eventType:string, eventAction:()=>void) {
    this.list.has(eventType) || this.list.set(eventType, []);
    if (this.list.get(eventType)) this.list.get(eventType).push(eventAction);
    return this;
  },

  emit(eventType:string) {
    this.list.get(eventType) &&
      this.list.get(eventType).forEach((cb:()=>void) => {
        cb();
      });
  }
};
function Wiki(props:{wikiDocuments:any}) {
    let match = useRouteMatch();
    function onCreateDocument() {
      wikiEvent.emit('create');
    }
    document.title="Wiki | Beep";
    return (
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <Paper>
            <WikiMenuFragment wikiDocuments={props.wikiDocuments} />
            </Paper>
          </Grid>
          <Grid item sm={9}>
          <Switch>
            <Route path={`${match.path}/create`}>
              <Suspense fallback={<Backdrop open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>}>
                <CreateWikiDocument onCreateDocument={onCreateDocument} />
              </Suspense>
              </Route>
            <Route path={`${match.path}/:documentId/create`}>
            <Suspense fallback={<Backdrop open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>}>
                <CreateWikiDocument onCreateDocument={onCreateDocument} />
              </Suspense>
              </Route>
            <Route path={`${match.path}/:documentId`} render={({match}) => {
              return (<Suspense fallback={<Backdrop open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>}>
                <WikiDocument organisationId={match.params.organisationId} documentId={match.params.documentId} />
              </Suspense>);
            }}>
            </Route>
            <Route path={match.path}>
            Welcome to the Wiki
            </Route>
          </Switch>
          </Grid>
        </Grid>);
  }
  function WikiMenu(props:{wikiDocuments:Wiki_wikiDocuments | null,relay:any}) {
    let match = useRouteMatch();
    let history = useHistory();
    let { organisationId } : {organisationId:string}= useParams();
    useEffect(() => {
      wikiEvent.on("create",()=> {
        props.relay.refetch(
          {organisationId: organisationId},
          null,
          () => {},
          {force: true},
        );
      });
    });
    function drawChildTree(id:string) {
      return props.wikiDocuments?.wikiDocuments.edges.filter(d=>d.node.parentDocument?.id===id).map((edge: any) =>(
        <TreeItem key={edge.node.id} nodeId={edge.node.id} label={edge.node.title} onLabelClick={()=>{
          history.push(match.url+"/"+edge.node.id)
        }} children={drawChildTree(edge.node.id)} />
      ));
    }
    return (
      <TreeView
      defaultCollapseIcon={<Icon path={mdiChevronDown} size={1} />}
      defaultExpandIcon={<Icon path={mdiChevronRight} size={1} />}
    >
    {props.wikiDocuments?.wikiDocuments.edges.filter(d=>d.node.parentDocument===null).map((edge: any) =>(
      <TreeItem key={edge.node.id} nodeId={edge.node.id} label={edge.node.title} onLabelClick={()=>{
        history.push(match.url+"/"+edge.node.id)
      }} children={drawChildTree(edge.node.id)} />
    ))}
    <TreeItem nodeId="wd_add" icon={<Icon path={mdiPlus} size={1} />} label="New Document" onClick={()=>{
        history.push(match.url+"/create");
      }} />
    </TreeView>);
  }
  var WikiMenuFragment=createRefetchContainer(
    WikiMenu,
    {
      wikiDocuments: graphql`
        fragment Wiki_wikiDocuments on Organisation {
            wikiDocuments(first:10000) {
              edges {
                node {
                  id
                  title
                  parentDocument {
                    id
                  }
                }
              }
            }
          }
      `,
    },
    graphql`
    query WikiMenuRefetchQuery($organisationId: ID!) {
      organisation(id:$organisationId) {
        ...Wiki_wikiDocuments
      }
    }
  `
  );

  export default Wiki;