/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ActivityLog_object = {
    readonly activityLog: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly type: string;
                readonly action: string;
                readonly timestamp: string;
                readonly input: string | null;
            };
            readonly cursor: string;
        }>;
    };
    readonly " $refType": "ActivityLog_object";
};
export type ActivityLog_object$data = ActivityLog_object;
export type ActivityLog_object$key = {
    readonly " $data"?: ActivityLog_object$data;
    readonly " $fragmentRefs": FragmentRefs<"ActivityLog_object">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityLog_object",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityLogConnection",
      "kind": "LinkedField",
      "name": "activityLog",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityLogEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ActivityLog",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "action",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timestamp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "input",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Object",
  "abstractKey": "__isObject"
};
(node as any).hash = 'f18d22ae2c581dbdf033adb1d70513ab';
export default node;
