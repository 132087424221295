import { Grid, Button, TextField, IconButton } from '@material-ui/core';
import React, { useReducer, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment, useMutation } from 'react-relay/hooks';
import { Metadata_object$key } from './__generated__/Metadata_object.graphql';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { Metadata_Edit_Mutation } from './__generated__/Metadata_Edit_Mutation.graphql';

export function Metadata(props:{object:Metadata_object$key, organisationId:string}) {
  const [editing, setEditing] = useState(false);
  const [editableData, setEditableData] = useReducer((state: any[], action: { type: any; index: any | null;field: any | null;value: any | null; }) => {
    switch (action.type) {
      case 'add':
        return [
            ...state,
            {
              name: '',
              value:'',
            }
          ];
      case 'edit':
          var ts=[...state];
          ts[action.index][action.field]=action.value;
          return ts;
      case 'set':
          return action.value;
      case 'remove':
        // keep every item except the one we want to remove
        return state.filter((_: any, index: any) => index !== action.index);
      default:
        return state;
    }
  }, []);

  const [edit]=useMutation<Metadata_Edit_Mutation>(graphql`
  mutation Metadata_Edit_Mutation(
    $organisationId:ID!,
    $objectId:ID!,
    $metadata:[MetadataInput]
  ) {
    editMetadata(organisation: $organisationId, object: $objectId, metadata: $metadata) {
      ...Metadata_object
      ...ActivityLog_object
    }
  }
`);
    const data = useFragment(
        graphql`
          fragment Metadata_object on Object {
            id
            metadata {
            name
            value
          }
          }
        `,
        props.object,
      );
  if(data?.metadata!==null && data?.metadata!==undefined) {
    return (<div>
        <div style={{float:'right'}}><Button size="small" variant="contained" disabled={editing} onClick={() => {setEditableData({type:'set',value:data.metadata.map(a=>{return {...a}}), field:null, index:null});setEditing(true);}}>Edit Metadata</Button></div>
        <h3>Metadata</h3>
        <div hidden={editing}>
        <Grid container spacing={1}>
        {(data.metadata ?? []).map((meta, index:number) => {
                return (
                    <Grid item xs={12} key={index}>
                        <Grid container id="metadataContainer">
                            <Grid item xs={2} style={{color:'#999'}} >{meta.name}</Grid>
                            <Grid item xs={10}>{meta.value}</Grid>
                        </Grid>
                    </Grid>
                );
            })}
    </Grid></div>
    <div hidden={!editing}>
        <Grid container spacing={2}>
        {(editableData ?? []).map((meta: { name: string; value: string; }, index:number) => {
                return (
                    <Grid item xs={12} key={index}>
                        <Grid container spacing={1} id="metadataContainer">
                            <Grid item xs={2} style={{color:'#999'}}><TextField size="small" fullWidth variant="outlined" label="Name" value={meta.name} onChange={(event) => {setEditableData({type:'edit',index:index,field:'name',value:event.target.value});}} /></Grid>
                            <Grid item xs={9}><TextField size="small" fullWidth variant="outlined" label="Value" value={meta.value} onChange={(event) => {setEditableData({type:'edit',index:index,field:'value',value:event.target.value});}} /></Grid>
                            <Grid item xs={1}><IconButton size="small" onClick={() => {setEditableData({type:'remove',index:index,field:null,value:null});}} aria-label="delete"><Icon path={mdiDelete} size={1} /></IconButton></Grid>
                        </Grid>
                    </Grid>
                );
            })}
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <Button size="small"  onClick={() => {setEditableData({type:'add',index:null,field:null,value:null});}}>Add another item</Button>
                    </Grid>
                    <Grid item xs={7} style={{textAlign:'right'}}>
                        <Button variant="contained" size="small" onClick={() => setEditing(false)}>Cancel</Button>
                        <Button variant="contained" size="small" color="primary" onClick={() => {
                            var md:any={};
                            editableData.forEach((element: { name: string; value: any; }) => {
                                md[element.name]=element.value;
                            });
                            data.metadata.forEach((element) => {
                                if(md[element.name]===undefined) {
                                    md[element.name]=null;
                                }else{
                                    if(md[element.name]===element.value) {
                                        delete md[element.name];
                                    }
                                }
                            });
                            var op=Object.keys(md).map(e => {return {name:e,value:md[e]}});
                            edit({
                                variables:{organisationId:props.organisationId,objectId:data.id, metadata:op},
                                onCompleted(data) {
                                  setEditing(false);
                                },
                              });
                        }}>Save</Button>
                    </Grid>
                </Grid>
            </Grid>
    </Grid></div></div>);
  }else{
    return (<div></div>);
  }   
}