/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrganisationSettings_modules = {
    readonly modules: ReadonlyArray<{
        readonly name: string;
        readonly settings: ReadonlyArray<string | null> | null;
    }>;
    readonly " $refType": "OrganisationSettings_modules";
};
export type OrganisationSettings_modules$data = OrganisationSettings_modules;
export type OrganisationSettings_modules$key = {
    readonly " $data"?: OrganisationSettings_modules$data;
    readonly " $fragmentRefs": FragmentRefs<"OrganisationSettings_modules">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganisationSettings_modules",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Module",
      "kind": "LinkedField",
      "name": "modules",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "settings",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organisation",
  "abstractKey": null
};
(node as any).hash = 'cdf22e1be201fc3fc5019515757a4754';
export default node;
