import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {RelayEnvironmentProvider}  from 'react-relay/hooks';

import {
  BrowserRouter as Router
} from "react-router-dom";

import environment from './relayenv';
import { Backdrop,CircularProgress } from '@material-ui/core';

ReactDOM.render(
  <React.StrictMode>
    <RelayEnvironmentProvider environment={environment}>
    <Router>
      <Suspense fallback={<Backdrop open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>}>
      <App />
      </Suspense>
    </Router>
    </RelayEnvironmentProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
