/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EntityQueryVariables = {
    organisationId: string;
    entityId: string;
};
export type EntityQueryResponse = {
    readonly organisation: {
        readonly entity: {
            readonly id: string;
            readonly organisations: ReadonlyArray<{
                readonly organisation: {
                    readonly name: string | null;
                };
                readonly role: string;
            }>;
            readonly name: string | null;
            readonly createdDate: string;
            readonly modifiedDate: string;
            readonly dateOfBirth?: string | null;
            readonly " $fragmentRefs": FragmentRefs<"Metadata_object" | "ActivityLog_object">;
        } | null;
    } | null;
};
export type EntityQuery = {
    readonly response: EntityQueryResponse;
    readonly variables: EntityQueryVariables;
};



/*
query EntityQuery(
  $organisationId: ID!
  $entityId: ID!
) {
  organisation(id: $organisationId) {
    entity(id: $entityId) {
      __typename
      id
      organisations {
        organisation {
          name
          id
        }
        role
      }
      name
      createdDate
      modifiedDate
      ...Metadata_object
      ...ActivityLog_object
      ... on PersonEntity {
        dateOfBirth
      }
    }
    id
  }
}

fragment ActivityLog_object on Object {
  __isObject: __typename
  activityLog {
    edges {
      node {
        type
        action
        timestamp
        input
      }
      cursor
    }
  }
}

fragment Metadata_object on Object {
  __isObject: __typename
  id
  metadata {
    name
    value
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "entityId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modifiedDate",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfBirth",
      "storageKey": null
    }
  ],
  "type": "PersonEntity",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EntityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "entity",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganisationRole",
                "kind": "LinkedField",
                "name": "organisations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "kind": "LinkedField",
                    "name": "organisation",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Metadata_object"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActivityLog_object"
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EntityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "entity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganisationRole",
                "kind": "LinkedField",
                "name": "organisations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "kind": "LinkedField",
                    "name": "organisation",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Metadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityLogConnection",
                    "kind": "LinkedField",
                    "name": "activityLog",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ActivityLogEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ActivityLog",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "action",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timestamp",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "input",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Object",
                "abstractKey": "__isObject"
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "850ba0a7897dbb7ace2df7e6edff490c",
    "id": null,
    "metadata": {},
    "name": "EntityQuery",
    "operationKind": "query",
    "text": "query EntityQuery(\n  $organisationId: ID!\n  $entityId: ID!\n) {\n  organisation(id: $organisationId) {\n    entity(id: $entityId) {\n      __typename\n      id\n      organisations {\n        organisation {\n          name\n          id\n        }\n        role\n      }\n      name\n      createdDate\n      modifiedDate\n      ...Metadata_object\n      ...ActivityLog_object\n      ... on PersonEntity {\n        dateOfBirth\n      }\n    }\n    id\n  }\n}\n\nfragment ActivityLog_object on Object {\n  __isObject: __typename\n  activityLog {\n    edges {\n      node {\n        type\n        action\n        timestamp\n        input\n      }\n      cursor\n    }\n  }\n}\n\nfragment Metadata_object on Object {\n  __isObject: __typename\n  id\n  metadata {\n    name\n    value\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd1783c34d2bad4dd2a3ec37eb5734604';
export default node;
