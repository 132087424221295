/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Wiki_wikiDocuments = {
    readonly wikiDocuments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly parentDocument: {
                    readonly id: string;
                } | null;
            };
        }>;
    };
    readonly " $refType": "Wiki_wikiDocuments";
};
export type Wiki_wikiDocuments$data = Wiki_wikiDocuments;
export type Wiki_wikiDocuments$key = {
    readonly " $data"?: Wiki_wikiDocuments$data;
    readonly " $fragmentRefs": FragmentRefs<"Wiki_wikiDocuments">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Wiki_wikiDocuments",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000
        }
      ],
      "concreteType": "WikiDocumentConnection",
      "kind": "LinkedField",
      "name": "wikiDocuments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WikiDocumentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WikiDocument",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WikiDocument",
                  "kind": "LinkedField",
                  "name": "parentDocument",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "wikiDocuments(first:10000)"
    }
  ],
  "type": "Organisation",
  "abstractKey": null
};
})();
(node as any).hash = 'c34c683813cd2a361cb7571cbe89f3e5';
export default node;
