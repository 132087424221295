import React from 'react';
import logo from './logo_dark.svg';
import clsx from 'clsx';
import { Fab, AppBar, Toolbar, CircularProgress, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, createStyles, makeStyles, Theme, CssBaseline, IconButton, Avatar, fade, InputBase, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiCards, mdiInbox, mdiBellOutline, mdiMagnify, mdiFileDocumentMultiple, mdiCog, mdiFormatListCheckbox, mdiAccountGroup, mdiViewDashboard, mdiPlus } from '@mdi/js';
import { graphql } from 'babel-plugin-relay/macro'
import { QueryRenderer } from 'react-relay'

import { OrganisationUserQuery } from "./__generated__/OrganisationUserQuery.graphql"

import { OrganisationQuery } from "./__generated__/OrganisationQuery.graphql"


import environment from './relayenv';

import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import Wiki from './modules/wiki/Wiki';
import OrganisationSettings from './OrganisationSettings';
import Transactions from './Transactions';
import { useLazyLoadQuery } from 'react-relay/hooks';
import Entities from './Entities';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      marginLeft: theme.spacing(7) + 1,
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(9) + 1,
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    grow: {
      flexGrow: 1,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    logo: {
      display:'block',
      backgroundImage:'url('+logo+')',
      backgroundPosition:'center',
      backgroundRepeat:'no-repeat',
      height:'100%',
      width:'100%',
      fontSize:0,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    search: {
      flexGrow: 1,
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:focus': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width:'100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      display:'block',
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

function Organisation() {
  
  let { organisationId }:{organisationId:string} = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);


  const handleDrawerToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpen(!open);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const menuId = 'primary-search-account-menu';
  let match = useRouteMatch();

  const data = useLazyLoadQuery<OrganisationQuery>(
    graphql`
    query OrganisationQuery($organisationId: ID!) {
      organisation(id:$organisationId) {
        name
        modules {
          name
          settings
        }
        ...Wiki_wikiDocuments
        ...OrganisationSettings_users
        ...OrganisationSettings_modules
        ...Transactions_organisation
        ...Entities_organisation
      }
    }
    `,
    {organisationId:organisationId},
  );
  var orgName=data?.organisation?.name;
          document.title=orgName===null || orgName===undefined ? "Dashboard | Beep" : orgName+" | Beep";
          return (<div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}>
            <Toolbar>
            <div className={classes.search}>
                  <div className={classes.searchIcon}>
                  <Icon path={mdiMagnify} size={1} />
                  </div>
                  <InputBase
                    placeholder={"Search "+data?.organisation?.name+"…"}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
            <IconButton
                  color="inherit"
                  aria-label="Notifications"
                  edge="end"
                >
                  <Icon path={mdiBellOutline} size={1} />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="Account"
                  edge="end"
                  aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                >
                  <Avatar>EG</Avatar>
                </IconButton>
            </Toolbar>
          </AppBar>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem component={Link} to="https://dashboard.beep.dev/">Switch Organisation</MenuItem>
            <MenuItem component={Link} to="https://dashboard.beep.dev/settings">Account Settings</MenuItem>
            <MenuItem component={Link} to="https://dashboard.beep.dev/logout">Logout</MenuItem>
          </Menu>
          <Drawer
                  variant="permanent"
                  className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  })}
                  classes={{
                    paper: clsx({
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    }),
                  }}    
                >
                  <div className={classes.toolbar}>
                    <a href="/" className={classes.logo} onClick={handleDrawerToggle}>
                    Beep
                    </a>
              </div>
              <Divider />
              <List>
              <ListItem button key="Dashboard" component={Link} to={`${match.url}`}>
                  <ListItemIcon><Icon path={mdiViewDashboard} size={1} /></ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <div hidden={data.organisation?.modules.filter((m)=>['transit_operator','transit_issuer','shop'].includes(m.name)).length===0}>
                <ListItem button key="Transactions" component={Link} to={`${match.url}/transactions`}>
                  <ListItemIcon><Icon path={mdiFormatListCheckbox} size={1} /></ListItemIcon>
                  <ListItemText primary="Transactions" />
                </ListItem>
                <ListItem button key="Entities" component={Link} to={`${match.url}/entities`}>
                  <ListItemIcon><Icon path={mdiFormatListCheckbox} size={1} /></ListItemIcon>
                  <ListItemText primary="Entities" />
                </ListItem>
                </div>
                <div hidden={data.organisation?.modules.filter((m)=>['transit_issuer'].includes(m.name)).length===0}>
                <ListItem button key="Cardholders" component={Link} to={`${match.url}/entities`}>
                  <ListItemIcon><Icon path={mdiAccountGroup} size={1} /></ListItemIcon>
                  <ListItemText primary="Cardholders" />
                </ListItem>
                </div>
                <div hidden={data.organisation?.modules.filter((m)=>['transit_issuer','shop'].includes(m.name)).length===0}>
                <ListItem button key="Products" component={Link} to={`${match.url}/products`}>
                  <ListItemIcon><Icon path={mdiInbox} size={1} /></ListItemIcon>
                  <ListItemText primary="Products" />
                </ListItem>
                </div>
                <div hidden={data.organisation?.modules.filter((m)=>m.name==='wiki').length===0}>
                <ListItem button key="Wiki" component={Link} to={`${match.url}/wiki`}>
                  <ListItemIcon><Icon path={mdiFileDocumentMultiple} size={1} /></ListItemIcon>
                  <ListItemText primary="Wiki" />
                </ListItem>
                </div>
              </List>
              <Divider />
            
            <List>
            <ListItem button key="Settings" component={Link} to={`${match.url}/settings`}>
                  <ListItemIcon><Icon path={mdiCog} size={1} /></ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>
            </List>
                </Drawer>
                <main className={classes.content}>
              <div className={classes.toolbar} />
              <Switch>
              <Route path={`${match.path}/wiki`}>
                  <Wiki wikiDocuments={data.organisation} />
                </Route>
                <Route path={`${match.path}/cardholders`}>
                  <About organisationId={organisationId} />
                </Route>
                <Route path={`${match.path}/products`}>
                  <Users organisationId={organisationId} />
                </Route>
                <Route path={`${match.path}/transactions`}>
                  <Transactions organisation={data.organisation} />
                </Route>
                <Route path={`${match.path}/entities`}>
                  <Entities organisation={data.organisation} />
                </Route>
                <Route path={`${match.path}/settings`}>
                  <OrganisationSettings users={data.organisation} modules={data.organisation} />
                </Route>
                <Route path={`${match.path}/`}>
                  <OrganisationDashboard organisationId={organisationId} organisation={data.organisation} />
                </Route>
              </Switch>
            </main>
          </div>);
  
}

function OrganisationDashboard(props:any) {
  const classes = useStyles();
  return <div>
    <h2>Dashboard for {props.organisation?.name}</h2>
    <Typography paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
        facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
        gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
        donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
        adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras.
        Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis
        imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget
        arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
        donec massa sapien faucibus et molestie ac.
      </Typography>
      <Typography paragraph>
        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper eget nulla
        facilisi etiam dignissim diam. Pulvinar elementum integer enim neque volutpat ac
        tincidunt. Ornare suspendisse sed nisi lacus sed viverra tellus. Purus sit amet volutpat
        consequat mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis risus sed
        vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in. In
        hendrerit gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem et
        tortor. Habitant morbi tristique senectus et. Adipiscing elit duis tristique sollicitudin
        nibh sit. Ornare aenean euismod elementum nisi quis eleifend. Commodo viverra maecenas
        accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam ultrices sagittis orci a.
      </Typography>
      <Fab color="primary" className={classes.fab} aria-label="add">
        <Icon path={mdiCards} size={1} />
        </Fab>
    </div>;
}

function About(props:any) {
  const classes = useStyles();
  return <div>
    <h2>Cardholders</h2>
    <Fab color="primary" className={classes.fab} aria-label="add">
        <Icon path={mdiPlus} size={1} />
        </Fab>
    <QueryRenderer<OrganisationUserQuery>
        environment={environment}
        query={graphql`
        query OrganisationUserQuery($organisationId: ID!){
          organisation(id:$organisationId) {
            users {
              edges {
                node {
                  user {
                    id
                    firstName
                    surname
                    emailAddress
                  }
                }
              }
            }
          }
        }
        `}
        variables={{organisationId: props.organisationId}}
        render={({error, props}) => {
          if (error) {
            return <div>Error!</div>;
          }
          if (!props) {
            return (<CircularProgress color="inherit" />);
          }
          return (<TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {props?.organisation?.users.edges.map((edge) =>(
                  <TableRow key={edge.node.user.id}>
                    <TableCell component="th" scope="row">
                    {edge.node.user.firstName} {edge.node.user.surname}
                    </TableCell>
                    <TableCell><code>{edge.node.user.emailAddress}</code></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>);
        }}
      />
    </div>;
}



function Users(props:any) {
  return <h2>Products</h2>;
}

export default Organisation;
