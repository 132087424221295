import React, { Suspense } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Backdrop, CircularProgress } from '@material-ui/core';
import { graphql } from 'babel-plugin-relay/macro'
import { enGB } from 'date-fns/locale'

//import type {TransactionsPaginationQuery_organisation$key} from "./__generated__/TransactionsPaginationQuery.graphql";

import {
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import { usePaginationFragment } from 'react-relay/hooks';
import { TransactionsPaginationQuery } from './__generated__/TransactionsPaginationQuery.graphql';
import { formatRelative } from 'date-fns';
import { Transaction } from './Transaction';

function Transactions(props : {organisation: any}) {
    let match = useRouteMatch();
    //const classes = useStyles();
    //let { organisationId }:{organisationId:string} = useParams();
    /*return(
        <div></div>
    );*/
    const {data/*, loadNext*/} = usePaginationFragment<TransactionsPaginationQuery, any>(
        graphql`
          fragment Transactions_organisation on Organisation
          @refetchable(queryName: "TransactionsPaginationQuery")
          @argumentDefinitions(
            count: {type: "Int", defaultValue: 10},
            cursor: {type: "Int", defaultValue: -1},
          ) {
            transactions(first: $count, after: $cursor)
            @connection(key: "Transactions_organisation_transactions") {
              edges {
                node {
                  id
                  transactionType
                  status
                  transactionDate
                }
              }
            }
          }
        `,
        props.organisation,
      );    
    return (
        <Switch>
            <Route path={`${match.path}/:transactionId`} render={({match}) => {
              return (<Suspense fallback={<Backdrop open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>}>
                <Transaction organisationId={match.params.organisationId} transactionId={match.params.transactionId} />
              </Suspense>);
            }}>
            </Route>
            <Route path={match.path}>
                <h2>Transactions</h2>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                        <TableCell>Transaction</TableCell>
                        <TableCell>Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {(data.transactions?.edges ?? []).map((edge: { node: { id: React.Key | null | undefined; transactionType: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; status: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; transactionDate: number | null; }; }) => {
                        return (
                            <Suspense fallback={<div></div>}>
                                <TableRow key={edge.node.id}>
                                    <TableCell component="th" scope="row">
                                    <Link to={`${match.url}/${edge.node.id}`}>{edge.node.transactionType} {edge.node.status}</Link>
                                    </TableCell>
                                    <TableCell>{edge.node.transactionDate===null ? '' : formatRelative(new Date(edge.node.transactionDate), new Date(),{locale:enGB})}</TableCell>
                                </TableRow>
                            </Suspense>
                        );
                    })}
                    </TableBody>
                    </Table>
                </TableContainer>
            </Route>
          </Switch>);
  }

export default Transactions;