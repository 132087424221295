/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrganisationUserQueryVariables = {
    organisationId: string;
};
export type OrganisationUserQueryResponse = {
    readonly organisation: {
        readonly users: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly user: {
                        readonly id: string | null;
                        readonly firstName: string | null;
                        readonly surname: string | null;
                        readonly emailAddress: string | null;
                    };
                };
            }>;
        };
    } | null;
};
export type OrganisationUserQuery = {
    readonly response: OrganisationUserQueryResponse;
    readonly variables: OrganisationUserQueryVariables;
};



/*
query OrganisationUserQuery(
  $organisationId: ID!
) {
  organisation(id: $organisationId) {
    users {
      edges {
        node {
          user {
            id
            firstName
            surname
            emailAddress
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserOrganisationConnection",
  "kind": "LinkedField",
  "name": "users",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserOrganisationEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserOrganisation",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "surname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emailAddress",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganisationUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6603c7908eeaf1714e86b6d31801ed81",
    "id": null,
    "metadata": {},
    "name": "OrganisationUserQuery",
    "operationKind": "query",
    "text": "query OrganisationUserQuery(\n  $organisationId: ID!\n) {\n  organisation(id: $organisationId) {\n    users {\n      edges {\n        node {\n          user {\n            id\n            firstName\n            surname\n            emailAddress\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a555b63ac52afd4cd61ec9a01dc28b83';
export default node;
