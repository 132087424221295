/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrganisationQueryVariables = {
    organisationId: string;
};
export type OrganisationQueryResponse = {
    readonly organisation: {
        readonly name: string | null;
        readonly modules: ReadonlyArray<{
            readonly name: string;
            readonly settings: ReadonlyArray<string | null> | null;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"Wiki_wikiDocuments" | "OrganisationSettings_users" | "OrganisationSettings_modules" | "Transactions_organisation" | "Entities_organisation">;
    } | null;
};
export type OrganisationQuery = {
    readonly response: OrganisationQueryResponse;
    readonly variables: OrganisationQueryVariables;
};



/*
query OrganisationQuery(
  $organisationId: ID!
) {
  organisation(id: $organisationId) {
    name
    modules {
      name
      settings
    }
    ...Wiki_wikiDocuments
    ...OrganisationSettings_users
    ...OrganisationSettings_modules
    ...Transactions_organisation
    ...Entities_organisation
    id
  }
}

fragment Entities_organisation on Organisation {
  entities(first: 10, after: -1) {
    edges {
      node {
        __typename
        id
        name
        createdDate
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  id
}

fragment OrganisationSettings_modules on Organisation {
  modules {
    name
    settings
  }
}

fragment OrganisationSettings_users on Organisation {
  users {
    edges {
      node {
        user {
          id
          firstName
          surname
          emailAddress
        }
      }
    }
  }
}

fragment Transactions_organisation on Organisation {
  transactions(first: 10, after: -1) {
    edges {
      node {
        __typename
        id
        transactionType
        status
        transactionDate
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  id
}

fragment Wiki_wikiDocuments on Organisation {
  wikiDocuments(first: 10000) {
    edges {
      node {
        id
        title
        parentDocument {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Module",
  "kind": "LinkedField",
  "name": "modules",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settings",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "after",
    "value": -1
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Wiki_wikiDocuments"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganisationSettings_users"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganisationSettings_modules"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Transactions_organisation"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Entities_organisation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000
              }
            ],
            "concreteType": "WikiDocumentConnection",
            "kind": "LinkedField",
            "name": "wikiDocuments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WikiDocumentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WikiDocument",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WikiDocument",
                        "kind": "LinkedField",
                        "name": "parentDocument",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "wikiDocuments(first:10000)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserOrganisationConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserOrganisationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserOrganisation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "surname",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emailAddress",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "TransactionConnection",
            "kind": "LinkedField",
            "name": "transactions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "transactionType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "transactionDate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "transactions(after:-1,first:10)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Transactions_organisation_transactions",
            "kind": "LinkedHandle",
            "name": "transactions"
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "EntityConnection",
            "kind": "LinkedField",
            "name": "entities",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdDate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "entities(after:-1,first:10)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Entities_organisation_entities",
            "kind": "LinkedHandle",
            "name": "entities"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8089329bc6ad99d20d29fec1dc4664a3",
    "id": null,
    "metadata": {},
    "name": "OrganisationQuery",
    "operationKind": "query",
    "text": "query OrganisationQuery(\n  $organisationId: ID!\n) {\n  organisation(id: $organisationId) {\n    name\n    modules {\n      name\n      settings\n    }\n    ...Wiki_wikiDocuments\n    ...OrganisationSettings_users\n    ...OrganisationSettings_modules\n    ...Transactions_organisation\n    ...Entities_organisation\n    id\n  }\n}\n\nfragment Entities_organisation on Organisation {\n  entities(first: 10, after: -1) {\n    edges {\n      node {\n        __typename\n        id\n        name\n        createdDate\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment OrganisationSettings_modules on Organisation {\n  modules {\n    name\n    settings\n  }\n}\n\nfragment OrganisationSettings_users on Organisation {\n  users {\n    edges {\n      node {\n        user {\n          id\n          firstName\n          surname\n          emailAddress\n        }\n      }\n    }\n  }\n}\n\nfragment Transactions_organisation on Organisation {\n  transactions(first: 10, after: -1) {\n    edges {\n      node {\n        __typename\n        id\n        transactionType\n        status\n        transactionDate\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment Wiki_wikiDocuments on Organisation {\n  wikiDocuments(first: 10000) {\n    edges {\n      node {\n        id\n        title\n        parentDocument {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '86a7e2f312e556d42d255f6958526725';
export default node;
