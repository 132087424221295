/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrganisationSettings_users = {
    readonly users: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly user: {
                    readonly id: string | null;
                    readonly firstName: string | null;
                    readonly surname: string | null;
                    readonly emailAddress: string | null;
                };
            };
        }>;
    };
    readonly " $refType": "OrganisationSettings_users";
};
export type OrganisationSettings_users$data = OrganisationSettings_users;
export type OrganisationSettings_users$key = {
    readonly " $data"?: OrganisationSettings_users$data;
    readonly " $fragmentRefs": FragmentRefs<"OrganisationSettings_users">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganisationSettings_users",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserOrganisationConnection",
      "kind": "LinkedField",
      "name": "users",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserOrganisationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserOrganisation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "surname",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "emailAddress",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organisation",
  "abstractKey": null
};
(node as any).hash = '8f7eb3163c9f3dc7f645ff0d74217957';
export default node;
