import { Grid, Typography, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks';
import { formatRelative } from 'date-fns';
import { enGB } from 'date-fns/locale';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import JSONPretty from 'react-json-pretty';
import XMLViewer from 'react-xml-viewer';
import { ActivityLog_object$key } from './__generated__/ActivityLog_object.graphql';
var JSONPrettyTheme = {
  main: 'line-height:1.3;color:#666;overflow-x:scroll;',
  error: 'line-height:1.3;color:#ed5f74;overflow-x:scroll;',
  key: 'color:#5469d4;',
  string: 'color:#09825d;',
  value: 'color:#ed5f74;',
  boolean: 'color:#067ab8;font-weight:600;',
};

export function ActivityLog(props:{object:ActivityLog_object$key}) {
  const [selected, setSelected] = useState("0");
    const data = useFragment(
        graphql`
          fragment ActivityLog_object on Object {
            activityLog {
            edges {
              node {
                type
                action
                timestamp
                input
              }
              cursor
            }
          }
          }
        `,
        props.object,
      );    
  if(data?.activityLog!==null && data?.activityLog!==undefined) {
    var selItem=(data?.activityLog.edges ?? []).filter((log)=>log.cursor===selected)[0];
    var preview: {} | null=null;
    if(selItem?.node.input?.substring(0,1)==='{') {
        preview=<JSONPretty data={selItem?.node.input} theme={JSONPrettyTheme}></JSONPretty>;
    }
    if(selItem?.node.input?.substring(0,1)==='<') {
        preview=<div style={{overflowX:'scroll'}}><XMLViewer xml={selItem?.node.input} /></div>;
    }
    var selElem=null;
    if(selItem!==null && selItem!==undefined) {
      selElem=<Paper variant="outlined" style={{padding:'8px'}}>
      <Typography><code>{selItem?.node.type}.<strong>{selItem?.node.action}</strong></code></Typography>
      <Typography><small>{selItem===null ? '' : formatRelative(new Date(selItem?.node?.timestamp), new Date(),{locale:enGB})}</small></Typography>
      {preview}
  </Paper>;
    }
    return (<Grid container spacing={1}>
        <Grid item xs={4}>
            <Timeline align="left">
        {(data?.activityLog.edges ?? []).map((log, i) => {
            var hasConnector=null;
            if(data?.activityLog.edges.length!==i+1) {
              hasConnector=<TimelineConnector />;
            }
              return (
                  <TimelineItem key={log.cursor}>
                      <TimelineSeparator>
                          <TimelineDot />
                          {hasConnector}
                      </TimelineSeparator>
                      <TimelineContent>
                      <Paper variant="outlined" style={{padding:'8px',cursor:'pointer',backgroundColor:log.cursor===selected ? '#eee' : ''}} onClick={() => setSelected(log.cursor)}>
                          <Typography><code>{log.node.type}.<strong>{log.node.action}</strong></code></Typography>
                          <Typography><small>{formatRelative(new Date(log.node.timestamp), new Date(),{locale:enGB})}</small></Typography>
                      </Paper>
                      </TimelineContent>
                  </TimelineItem>
              );
          })}
            </Timeline>
        </Grid>
        <Grid item xs={8}>
          {selElem}
        </Grid>
    </Grid>);
  }else{
    return (<div></div>);
  }   
}