import React from 'react';
import { Accordion, FormControlLabel, Switch as Toggle, AccordionSummary, AccordionDetails, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, createStyles, makeStyles, Theme, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiCog, mdiAccountGroup, mdiViewModule, mdiChevronDown } from '@mdi/js';
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer, commitMutation } from 'react-relay'

import environment from './relayenv';

import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

const controllable_modules=[{type:'wiki',name:'Wiki'},{type:'blog',name:'Blog'},{type:'crm',name:'CRM'},{type:'shop',name:'Shop'}];
const fixed_modules=[{type:'dashboard',name:'Dashboard'}];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

function OrganisationSettings(props : any) {
    let match = useRouteMatch();
    const classes = useStyles();
    let { organisationId }:{organisationId:string} = useParams();
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
    var modstate:any={};
    controllable_modules.forEach(e => {
      modstate[e.type]=props.modules?.modules?.filter((m: { name: string; })=>m.name===e.type).length===1;
    });
    const [state, setState] = React.useState(modstate);
  
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      console.log({organisationId,moduleName:event.target.name, active:event.target.checked, settings:[]});
      commitMutation(
        environment,
        {
          mutation:graphql`
          mutation OrganisationSettings_SaveModule_Mutation(
            $organisationId:ID!,
            $moduleName:String,
            $active:Boolean,
            $settings:[String]
          ) {
            editModuleSettings(organisation:$organisationId, moduleName:$moduleName, active:$active, settings: $settings) {
              id,
              modules {
                name
                settings
              }
            }
          }
        `,
          variables:{organisationId,moduleName:event.target.name, active:event.target.checked, settings:[]},
        },
      );
    };

    return (
      <Grid container spacing={3}>
        <Grid item sm={3}>
          <Paper>
          <List
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItem component={Link} to={`${match.url}`} button>
        <ListItemIcon><Icon path={mdiCog} size={1} /></ListItemIcon>
          <ListItemText primary="General Settings" />
        </ListItem>
        <ListItem component={Link} to={`${match.url}/users`} button>
        <ListItemIcon><Icon path={mdiAccountGroup} size={1} /></ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem button component={Link} to={`${match.url}/modules`}>
        <ListItemIcon><Icon path={mdiViewModule} size={1} /></ListItemIcon>
          <ListItemText primary="Modules" />
        </ListItem>
      </List>
          </Paper>
        </Grid>
        <Grid item sm={9}>
        <Switch>
              <Route path={`${match.path}/users`}>
              <div>
                <h2>Users</h2>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email Address</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props?.users?.users?.edges?.map((edge: any) =>(
                        <TableRow key={edge.node.user.id}>
                            <TableCell component="th" scope="row">
                            {edge.node.user.firstName} {edge.node.user.surname}
                            </TableCell>
                            <TableCell><code>{edge.node.user.emailAddress}</code></TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
              </div>
              </Route>
              <Route path={`${match.path}/modules`}>
              <div>
                <h2>Modules</h2>
                {controllable_modules.map(e => 
      <Accordion key={"mod-"+e.type} expanded={expanded === e.type} onChange={handleChange(e.type)}>
      <AccordionSummary
        expandIcon={<Icon path={mdiChevronDown} size={1} />}
        aria-controls={e.type+'-content'}
        id={e.type+'-header'}
      >
        <Typography className={classes.heading}>{e.name}</Typography>
        <Typography className={classes.secondaryHeading}>{state[e.type] ? "Enabled" : "Disabled"}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        <FormControlLabel
control={
<Toggle
  checked={state[e.type]}
  onChange={handleSwitchChange}
  name={e.type}
  color="primary"
/>
}
label="Enabled"
/>
        </Typography>
      </AccordionDetails>
    </Accordion>
    )}
    {fixed_modules.filter(a=> props.modules?.modules?.filter((m: { name: string; })=>m.name===a.type).length===1).map(e => 
      <Accordion expanded={expanded === e.type} onChange={handleChange(e.type)}>
      <AccordionSummary
        expandIcon={<Icon path={mdiChevronDown} size={1} />}
        aria-controls={e.type+'-content'}
        id={e.type+'-header'}
      >
        <Typography className={classes.heading}>{e.name}</Typography>
        <Typography className={classes.secondaryHeading}></Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        
        </Typography>
      </AccordionDetails>
    </Accordion>
    )}
                
              </div>
              </Route>
              <Route path={match.path}>
              <div>
                <h2>Organisation Settings</h2>
                <code>{props.organisationId}</code>
              </div>
              </Route>
            </Switch>
        </Grid>
      </Grid>);
  }


  var OrganisationSettingsFragment=createFragmentContainer(
    OrganisationSettings,
    {
      modules: graphql`
        fragment OrganisationSettings_modules on Organisation {
            modules {
                name
                settings
            }
          }
      `,
      users: graphql`
        fragment OrganisationSettings_users on Organisation {
            users {
                edges {
                  node {
                    user {
                      id
                      firstName
                      surname
                      emailAddress
                    }
                  }
                }
              }
          }
      `,
    }
  );

  export default OrganisationSettingsFragment;