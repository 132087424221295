import { Card, CardContent, CardHeader, Grid, Avatar } from '@material-ui/core';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { ActivityLog } from './components/ActivityLog';
import { Metadata } from './components/Metadata';
import { EntityQuery } from './__generated__/EntityQuery.graphql';

export function Entity(props:any) {
  const data = useLazyLoadQuery<EntityQuery>(
    graphql`
    query EntityQuery($organisationId: ID!,$entityId: ID!){
      organisation(id:$organisationId) {
        entity(id:$entityId) {
          id
          organisations{
            organisation{
              name
            }
            role
          }
          name
          createdDate
          modifiedDate
          ...Metadata_object
          ...ActivityLog_object
          ...on PersonEntity {
            dateOfBirth
          }
        }
      }
    }
    `,
    {organisationId:props.organisationId,entityId:props.entityId},
  );
  console.log(data);
  if(data?.organisation?.entity!==null && data?.organisation?.entity!==undefined) {
    var entity=data?.organisation?.entity;
    document.title=entity.name+ " | Beep";
    return (<div>
        <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <div style={{textAlign:'right'}}>
                                <code style={{fontSize:'12px'}}>{entity.id}</code>
                            </div>
                            <div style={{textAlign:'center'}}>
                                <Avatar style={{width:200,height:200,marginLeft:'auto',marginRight:'auto'}}>B</Avatar>
                                <h2>{entity.name}</h2>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={8}>
                <Card>
                        <CardContent>
                            <Metadata object={entity} organisationId={props.organisationId} />
                            <h3>Activity Log</h3>
                            <ActivityLog object={entity} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>);
  }else{
    return (<Card>
        <CardHeader title="Entity not found" />
      </Card>);
  }   
}