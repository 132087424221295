import { Card, CardContent, CardHeader, TextField, Button, Chip, Divider, IconButton, Grid, CardActionArea, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro'
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { WikiDocumentQuery } from './__generated__/WikiDocumentQuery.graphql';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import Editor from "rich-markdown-editor";
import { formatRelative } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { WikiDocument_Edit_Mutation } from './__generated__/WikiDocument_Edit_Mutation.graphql';
import { WikiDocument_Create_Mutation } from './__generated__/WikiDocument_Create_Mutation.graphql';
import { mdiPencil, mdiTextBoxPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';

export function WikiDocument(props:any) {
  const [editing, setEditing] = useState(false);
  const [articleText, setArticleText] = useState("");
  const [id, setId] = useState("");
  const [titleText, setTitleText] = useState("");
  if(props.documentId!==id) {
    setId(props.documentId);
    setTitleText("{NULL}");
    setArticleText("{NULL}");
  }
  let match = useRouteMatch();
  let history = useHistory();
  const data = useLazyLoadQuery<WikiDocumentQuery>(
    graphql`
    query WikiDocumentQuery($organisationId: ID!,$documentId: ID!){
      organisation(id:$organisationId) {
        wikiDocument(id:$documentId) {
          id
          title
          article
          modifiedDate
          createdDate
          wikiDocuments(first:3) {
            edges {
              node {
                id
                title
                excerpt
              }
            }
          }
        }
      }
    }
    `,
    {organisationId:props.organisationId,documentId:props.documentId},
  );
  const [edit]=useMutation<WikiDocument_Edit_Mutation>(graphql`
  mutation WikiDocument_Edit_Mutation(
    $organisationId:ID!,
    $documentId:ID!,
    $title:String,
    $article:String
  ) {
    editWikiDocument(organisation: $organisationId, wikiDocument: $documentId, input: {
      title:$title,
      article:$article
    }) {
      id,
      title,
      article
    }
  }
`);
  var title=data?.organisation?.wikiDocument?.title;
  if(title!==undefined && titleText==="{NULL}") setTitleText(title);
  if(data?.organisation?.wikiDocument?.article!==undefined && articleText==="{NULL}") setArticleText(data?.organisation?.wikiDocument?.article);
      document.title=title===undefined ? "Wiki Document | Beep" : title+ " | Beep";
      if(data?.organisation?.wikiDocument!==undefined && data?.organisation?.wikiDocument!==null) {
        var doc=data?.organisation?.wikiDocument;
        return (<div><Card>
          <CardHeader title={doc.title} />
          <CardContent>
            <div hidden={editing}>
              <span hidden={doc.modifiedDate===doc.createdDate}><Chip label={`Modified ${formatRelative(new Date(doc.modifiedDate), new Date(),{locale:enGB})}`} style={{margin:4}} /></span>
              <Chip label={`Created ${formatRelative(new Date(doc.createdDate), new Date(),{locale:enGB})}`} style={{margin:4}} />
              <IconButton aria-label="Edit" onClick={() => setEditing(true)}><Icon path={mdiPencil} size={1} /></IconButton>
              <IconButton aria-label="Add Sub Document" onClick={() => history.push(match.url+"/create")}><Icon path={mdiTextBoxPlusOutline} size={1} /></IconButton>
              <Divider variant="middle" />
            </div>
            <div hidden={!editing}>
              <div style={{textAlign:'right'}}>
              <Button id="articleSaveButton" variant="contained" color="primary" size="small" onClick={() => {
                var title=titleText || doc.title;
                var article=articleText || doc.article;
                edit({
                  variables:{organisationId:props.organisationId,documentId:props.documentId, title, article},
                  optimisticResponse:{
                    editWikiDocument: {
                      id:props.documentId,
                      title:title,
                      article:article
                    },
                  },
                  onCompleted(data) {
                    setEditing(false);
                  },
                });
              }}>Save</Button>
              <Button id="articleCancelButton" variant="contained" size="small" onClick={() => {
                setEditing(false);
                history.push(match.url);
              }}>Cancel</Button>
              </div>
              <TextField placeholder="Document Title" fullWidth variant="outlined" value={titleText} onChange={event=>{setTitleText(event.target.value);}} />
            </div>
          <div style={{margin:"16px",marginLeft:"24px"}}>
          <Editor
            defaultValue={doc.article}
            value={doc.article}
            readOnly={!editing}
            onChange={async value => {
              setArticleText(value());
            }}
            onSave={async done => {
              document.getElementById("articleSaveButton")?.click();
            }}
            onCancel={() => {
              document.getElementById("articleCancelButton")?.click();
            }}
          /></div>
          </CardContent>
        </Card>
        <div style={{marginTop:16}}>
        <Grid container spacing={3}>
          {doc.wikiDocuments.edges.map((edge: any) =>(
            <Grid key={`wd_card_${edge.node.id}`} item sm={4}>
            <Card>
      <CardActionArea href={`/${props.organisationId}/wiki/${edge.node.id}`}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {edge.node.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {edge.node.excerpt}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
          </Grid>
    ))}
      </Grid></div></div>);
      }else{
        return (<Card>
          <CardHeader title="Document not found" />
        </Card>);
      }
      
  
}

export function CreateWikiDocument(props:{onCreateDocument:(()=>void)|null}) {
  const [articleText, setArticleText] = useState("");
  const [titleText, setTitleText] = useState("");
  let { organisationId, documentId } :{organisationId:string,documentId:string} = useParams();
  let history = useHistory();
  const [create]=useMutation<WikiDocument_Create_Mutation>(graphql`
  mutation WikiDocument_Create_Mutation(
    $organisationId:ID!,
    $documentId:ID,
    $title:String,
    $article:String
  ) {
    createWikiDocument(organisation: $organisationId, input: {
      parentDocument:$documentId
      title:$title,
      article:$article
    }) {
      id,
      title,
      article
    }
  }
`);
  document.title="New Wiki Document | Beep";
    return (<Card>
      <CardHeader title="Create a new Wiki Document" />
      <CardContent>
        <form>
        <div>
          <Button id="articleSaveButton" variant="contained" onClick={() => {
            var title=titleText;
            var article=articleText;
            create({
              variables:{organisationId,documentId, title, article},
              updater: (store: { getRootField: (arg0: string) => any; }) => {
                const newItem = store.getRootField('createWikiDocument');
                if(props.onCreateDocument!=null) {
                  props.onCreateDocument();
                }
                history.push('/'+organisationId+"/wiki/"+newItem?.getDataID())
              },
            });
          }}>Save</Button>
          <Button id="articleCancelButton" variant="contained" onClick={() => {

          }}>Cancel</Button>
          <TextField name="title" placeholder="Document Title" fullWidth variant="outlined" onChange={event=>{setTitleText(event.target.value);}} />
        </div>
      <div style={{margin:"16px",marginLeft:"24px"}}>
      <Editor
        readOnly={false}
        onChange={async value => {
          setArticleText(value());
        }}
        onSave={async done => {
          document.getElementById("articleSaveButton")?.click();
        }}
        onCancel={() => {
          document.getElementById("articleCancelButton")?.click();
        }}
      /></div>
      </form>
      </CardContent>
    </Card>);
  
}