import { Card, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks';
import { TransactionQuery } from './__generated__/TransactionQuery.graphql';
import { formatRelative } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { ActivityLog } from './components/ActivityLog';
import { Metadata } from './components/Metadata';

export function Transaction(props:any) {
  const data = useLazyLoadQuery<TransactionQuery>(
    graphql`
    query TransactionQuery($organisationId: ID!,$transactionId: ID!){
      organisation(id:$organisationId) {
        transaction(id:$transactionId) {
          id
          transactionType
          organisations{
            organisation{
              name
            }
            role
          }
          status
          createdDate
          transactionDate
          modifiedDate
          user {
            id
            firstName
            surname
          }
          ...Metadata_object
          ...ActivityLog_object
          ...on TransitJourneyTransaction {
            startLocation {
              name
              lat
              lon
            }
            endLocation {
              name
              lat
              lon
            }
          }
        }
      }
    }
    `,
    {organisationId:props.organisationId,transactionId:props.transactionId},
  );
  if(data?.organisation?.transaction!==null && data?.organisation?.transaction!==undefined) {
    var transaction=data?.organisation?.transaction;
    document.title=transaction.transactionType+ " | Beep";
    return (<div><Card>
        <CardContent>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <div style={{fontSize:'12px'}}>{transaction.transactionType.replace("_",' ')}</div>
                </Grid>
                <Grid item xs={6} style={{textAlign:'right'}}>
                    <code style={{fontSize:'12px'}}>{transaction.id}</code>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item>
                    <div style={{color:'#999'}}>Date</div>
                    <div>{transaction.transactionDate===null ? '' : formatRelative(new Date(transaction.transactionDate), new Date(),{locale:enGB})}</div>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                    <div style={{color:'#999'}}>Status</div>
                    <div>{transaction.status}</div>
                </Grid>
            </Grid>
            <h3>Transaction Details</h3>
            <Grid container spacing={1}>
                <Grid item xs={2} style={{color:'#999'}}>Created Date</Grid>
                <Grid item xs={10}>{transaction.createdDate===null ? '' : formatRelative(new Date(transaction.createdDate), new Date(),{locale:enGB})}</Grid>
                <Grid item xs={2} style={{color:'#999'}}>Modified Date</Grid>
                <Grid item xs={10}>{transaction.modifiedDate===null ? '' : formatRelative(new Date(transaction.modifiedDate), new Date(),{locale:enGB})}</Grid>
            </Grid>
            
            <Metadata object={transaction} organisationId={props.organisationId} />
            <h3>Activity Log</h3>
            <ActivityLog object={transaction} />
            
        </CardContent>
      </Card></div>);
  }else{
    return (<Card>
        <CardHeader title="Transaction not found" />
      </Card>);
  }   
}