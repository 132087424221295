/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrganisationSettings_SaveModule_MutationVariables = {
    organisationId: string;
    moduleName?: string | null;
    active?: boolean | null;
    settings?: Array<string | null> | null;
};
export type OrganisationSettings_SaveModule_MutationResponse = {
    readonly editModuleSettings: {
        readonly id: string | null;
        readonly modules: ReadonlyArray<{
            readonly name: string;
            readonly settings: ReadonlyArray<string | null> | null;
        }>;
    } | null;
};
export type OrganisationSettings_SaveModule_Mutation = {
    readonly response: OrganisationSettings_SaveModule_MutationResponse;
    readonly variables: OrganisationSettings_SaveModule_MutationVariables;
};



/*
mutation OrganisationSettings_SaveModule_Mutation(
  $organisationId: ID!
  $moduleName: String
  $active: Boolean
  $settings: [String]
) {
  editModuleSettings(organisation: $organisationId, moduleName: $moduleName, active: $active, settings: $settings) {
    id
    modules {
      name
      settings
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "moduleName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "settings"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "active",
        "variableName": "active"
      },
      {
        "kind": "Variable",
        "name": "moduleName",
        "variableName": "moduleName"
      },
      {
        "kind": "Variable",
        "name": "organisation",
        "variableName": "organisationId"
      },
      {
        "kind": "Variable",
        "name": "settings",
        "variableName": "settings"
      }
    ],
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "editModuleSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "modules",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "settings",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationSettings_SaveModule_Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganisationSettings_SaveModule_Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "60b9e7118258eb18e73f8437f6dd114a",
    "id": null,
    "metadata": {},
    "name": "OrganisationSettings_SaveModule_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganisationSettings_SaveModule_Mutation(\n  $organisationId: ID!\n  $moduleName: String\n  $active: Boolean\n  $settings: [String]\n) {\n  editModuleSettings(organisation: $organisationId, moduleName: $moduleName, active: $active, settings: $settings) {\n    id\n    modules {\n      name\n      settings\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '73c1f1a9d430707b653b7e194e7bbe1d';
export default node;
